/*!
 * guavejuice v0.1.0 Frontend boilerplate
 * Copyright 2019-present Guave Interactive c/o Guave Studios GmbH
 * Licensed under MIT
 */

@import "config/all";
@import "mixins/all";
@import "vendors/all";
@import "basics/all";
@import "components/all";

@import "~beerslider/dist/BeerSlider.unmin.css";
